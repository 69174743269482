import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription, take } from 'rxjs';
import { CommonConstants } from 'src/app/common';
import { NotificationConstants } from 'src/app/common/constants';
import { NotificationsService, UploadFilesService } from 'src/app/common/services';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { IChecklistItem } from 'src/app/configuration/models';

@Component({
  selector: 'ignis-checklist-image-type',
  styleUrls: ['./checklist-image-type.component.scss'],
  templateUrl: './checklist-image-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistImageTypeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() form: FormGroup;
  @Input() item: IChecklistItem;
  @Input() deleteImages: Subject<void>;
  @Output() areFilesUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('uploadFileBtn', { read: ElementRef })
  public uploadFileBtn: ElementRef<HTMLInputElement>;

  files: File[] = [];
  uploadFileInput: HTMLInputElement;
  uploadFilesSuccess: boolean[] = [];
  isNotificationHidden: boolean = false;
  deleteImagesSubscription: Subscription;

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  uploadInProgress$: Observable<boolean> = this.loadingSubject.asObservable();

  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  notificationsService: NotificationsService = inject(NotificationsService);

  constructor(private uploadFileService: UploadFilesService) {}

  ngAfterViewInit(): void {
    this.uploadFileBtn.nativeElement.addEventListener('cancel', this.eventListener);
  }

  ngOnInit(): void {
    this.deleteImagesSubscription = this.deleteImages.subscribe(() => {
      this.isNotificationHidden = true;
      this.removeFiles();
    });
  }

  /* istanbul ignore next */
  eventListener = (evt: Event): void => {
    evt.stopImmediatePropagation();
  };

  /* istanbul ignore next */
  async uploadFiles(event: { target: { files: File[] } }): Promise<void> {
    const promises: Promise<void>[] = [];
    const filesToUpload: File[] = [];
    const files: File[] = event.target.files;

    if (!files.length) return;

    this.uploadFileInput = document.getElementById(this.item?.name) as HTMLInputElement;

    for (const file of files) {
      const promise: Promise<void> = new Promise<void>((resolve) => {
        const reader: FileReader = new FileReader();

        reader.onload = () => {
          if (this.verifyFileTypeToUploadAndPrepareToUpload(file)) {
            this.files.push(file);
            filesToUpload.push(file);
          }

          resolve();
        };

        reader.readAsDataURL(file);
      });

      promises.push(promise);
    }

    this.loadingSubject.next(true);

    await Promise.all(promises);

    this.saveFiles(filesToUpload);
  }

  /* istanbul ignore next */
  verifyFileTypeToUploadAndPrepareToUpload(file: File): boolean {
    const acceptedFileType: string[] = ['image/png', 'image/jpg', 'image/jpeg'];

    if (file.size > CommonConstants.maxSizeOfUploadedImages) {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        NotificationConstants.commonCodes.UPLOAD_LARGE_FILE_FAILED,
        NotificationConstants.commonCodes,
      );

      this.loadingSubject.next(false);

      return false;
    }

    if (acceptedFileType.includes(file.type)) {
      return true;
    }

    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      NotificationConstants.commonCodes.WRONG_FILE_TYPE_UPLOAD_FAILED,
      NotificationConstants.commonCodes,
    );

    this.loadingSubject.next(false);

    return false;
  }

  /* istanbul ignore next */
  saveFiles(files: File[]): void {
    files.forEach((file: File, index: number) => {
      this.uploadFileService
        .uploadFiles(file)
        .pipe(take(1))
        .subscribe({
          next: (response: HttpResponse<File>) => {
            const mediaUrl: string[] = response.headers?.get('Location').split('/');
            const filename: string = mediaUrl.pop();

            Object.defineProperty(file, 'name', {
              writable: true,
              value: filename,
            });

            this.handleUploadSuccess(files);
          },
          error: () => {
            files.splice(index, 1);
            this.errorHandler(NotificationConstants.commonCodes.UPLOAD_FILE_FAILED);
          },
        });
    });
  }

  handleUploadSuccess(files: File[]): void {
    this.uploadFilesSuccess.push(true);

    if (this.uploadFilesSuccess.length === files.length) {
      this.areFilesUploaded.emit(true);

      const olderFilenames: string[] = (this.form.get([this.item.id]).value as string[]) || [];
      const newFilenames: string[] = files.map((file: File) => file.name);

      this.form.get([this.item.id])?.patchValue([...olderFilenames, ...newFilenames]);
      this.item.itemValue.mediaLinks.push(...newFilenames);

      this.form.markAsDirty();

      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.SUCCESS,
        NotificationConstants.commonCodes.UPLOAD_FILE_SUCCESS,
        NotificationConstants.commonCodes,
      );

      this.form.updateValueAndValidity();

      this.uploadFilesSuccess = [];

      this.loadingSubject.next(false);
    }
  }

  removeUploadedFile(filename: string, index: number, isManualDelete: boolean = true): void {
    this.uploadFileService.deleteSelectedFile(filename).subscribe({
      next: () => {
        this.files.splice(index, 1);
        this.item?.itemValue?.mediaLinks.splice(index, 1);

        if (this.files.length < 1 || this.item?.itemValue?.mediaLinks.length < 1) {
          this.uploadFileInput = document.getElementById(this.item?.name) as HTMLInputElement;

          if (this.uploadFileInput) {
            this.uploadFileInput.value = null;
          }

          this.form.get([this.item?.id])?.setValue(null);

          if (this.item?.required) {
            this.form.markAsPristine();
          }
        }

        this.notificationsService.requestShowNotification(
          isManualDelete ? CommonConstants.notificationType.SUCCESS : CommonConstants.notificationType.HIDDEN,
          NotificationConstants.commonCodes.DELETE_FILE_SUCCESS,
          NotificationConstants.commonCodes,
        );

        this.cdr.detectChanges();
      },
      error: () => {
        this.errorHandler(NotificationConstants.commonCodes.DELETE_FILE_FAILED);
      },
    });

    this.areFilesUploaded.emit(this.files.length > 0);
  }

  errorHandler(message: INotificationMessage): void {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      message,
      NotificationConstants.commonCodes,
    );

    if (message === NotificationConstants.commonCodes.UPLOAD_FILE_FAILED) {
      this.loadingSubject.next(false);
      this.uploadFileInput.value = null;
    }

    this.cdr.detectChanges();
  }

  removeFiles(): void {
    if (this.files.length > 0) {
      this.files.forEach((file: File, index: number) => {
        this.removeUploadedFile(file.name, index, false);
      });
    }

    this.areFilesUploaded.emit(false);
  }

  ngOnDestroy(): void {
    this.deleteImagesSubscription.unsubscribe();
    this.uploadFileBtn?.nativeElement.removeEventListener('cancel', this.eventListener);
  }
}
